import React from 'react'
import "../../styles/_animations.scss";
import * as S from "./StoriesBannerStyles";
import { Link } from 'gatsby';
import StoriesCircle from './StoriesCircle';

function StoriesBanner() {
  return <>
    <S.Wrapper hero stories>
      <div>
        
          <h1 className="title">Stories <Link to="/about"><span className="by-tonye">By Tonye</span></Link></h1>
        
        <p className="short-desc">Stories are byte-sized summaries of posts from my blog, portfolio and code snippets.</p>
      </div>

      <div className="stories-circle-ctr">
        <StoriesCircle 
          img="/images/tonye_gradient.png"
          text="Tonye"
          url="/Stories"
          watched={false}
          inHeader={false}
        />
        <StoriesCircle 
          img="/images/tonye_gradient.png"
          text="Tonye"
          url="/Stories"
          watched={true}
          inHeader={false}
        />
        <StoriesCircle 
          img="/images/tonye_gradient.png"
          text="Tonye"
          url="/Stories"
          watched={false}
          inHeader={false}
        />
        <StoriesCircle 
          img="/images/tonye_gradient.png"
          text="Tonye"
          url="/Stories"
          watched={false}
          inHeader={false}
        />
        
        <StoriesCircle 
          link
          text="Tonye"
          url="/Stories"
        />
      </div>
    </S.Wrapper>
  </>
}

export default StoriesBanner
