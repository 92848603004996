import { SEO } from "@components/seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { isMobile } from 'react-device-detect';
import Cursor from "../components/Cursor/cursor";
import Section from "../components/_SectionTemplate";
import Layout from "../components/layout";
import Scroll from "../components/locomotiveScroll";
import "../styles/pages/_stories.scss";
import StoriesBanner from './../components/__stories/StoriesBanner';

const StoriesPage = ({ location }) => (
  <Layout location={location}>
    {!isMobile && <Cursor />}
    <Scroll callbacks={location} />
    <SEO title="Stories - Byte sized nuggets" />

    <Container fluid className="stories">
      <Row>
        <Col>
          <Section desc="stories">
            <div className="stories" data-scroll-section data-scroll-target="#stories" data-scroll data-scroll-speed="1">
              <StoriesBanner />
              {/* <TonyeMarquee content="I'm super passionate about everything creative. Technology, music, fitness, art & culture." /> */}

            </div>
          </Section>
        </Col>
      </Row>
    </Container>

  </Layout>
)

export default StoriesPage
