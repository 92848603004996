import LocomotiveScroll from "locomotive-scroll"
import { useEffect } from "react"
import "../styles/components/_locomotive.scss"
import { scroll } from "../theme"

const Scroll = callbacks => {
  useEffect(() => {
    let locomotiveScroll
    locomotiveScroll = new LocomotiveScroll({
      el: document.querySelector(scroll.container),
      //...scroll.options,
      inertia: 0.8,
      smooth: true,
      getDirection: true,
      mobile: {
        breakpoint: 0,
        smooth: true,
        getDirection: true,
      },
      tablet: {
        breakpoint: 0,
        smooth: true,
        getDirection: true,
      },
    })
    locomotiveScroll.update();
    window.scroll = locomotiveScroll; // Exposing to the global scope for ease of use.
    locomotiveScroll.on("scroll", func => {
      document.documentElement.setAttribute("data-direction", func.direction); // Update `data-direction` with scroll direction.
    })

    return () => {
      if (locomotiveScroll) locomotiveScroll.destroy()
    }
  }, [callbacks])

  return null
}

export default Scroll