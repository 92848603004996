import styled, { css } from "styled-components";

const breakpoints = {
  sm: "564px",
  lg: "1100px",
};

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 8px;

  ${({ hero }) =>
    hero &&
    css`
      @media (min-width: ${breakpoints.lg}) {
        grid-column: span 2;
        grid-template-columns: 6fr 4fr;
        grid-gap: 32px;
        padding-bottom: 0;
      }
    `}
  
    ${({ stories }) =>
    stories &&
    css`
      background: #fff;
      color: #000;
      min-height: 180px;
      margin-top: -10px;
      margin-bottom: 0;
      padding: 21px 30px 21px;
      border: 5px solid;
      Border-image-slice: 1;
      border-width: 8px;
      // border-image-source: linear-gradient(316deg, rgb(243 187 55) 0%, rgb(215 1 153) 50%, rgb(127 1 248) 100%);
      border-image-source: linear-gradient(316deg, rgb(243 187 55) 0%, rgb(215 1 153) 50%, rgb(243 187 55) 100%);
      animation: fade-in-top 1s ease;

      @media (min-width: ${breakpoints.lg}) {
        grid-column: span 2;
        grid-template-columns: 2.5fr 4fr;
        grid-gap: 32px;
        padding-bottom: 0;
      }

      .title {
        display: flex;
        align-items: center;
        font-family: "Poppins",serif;
        font-style: normal;
        font-weight: 600;
        font-size: 35px;
        line-height: 56px;

        a {
          display: flex;
        }
      }

      .by-tonye {
        padding: 6px 14px 3px;
        background: linear-gradient( 211deg,rgb(236 139 80) 0%,rgb(216 7 150) 100% );
        color: #fff;
        font-family: 'Khula';
        font-size: 14px;
        line-height: 1;
        margin: 0 0 0 17px;
        border-radius: 21px;
        display: inline-flex;
        align-items: center;
        height: 33px;
        margin-top: 0;
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
        transform-origin: left;
        transform: scale(0.8);

        &:hover {
          opacity: 0.8;
          transition: opacity 0.5s ease-in-out;
        }
      }

      .short-desc {
        line-height: 1.4;
        font-family: "Khula",sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
      }

      .stories-circle-ctr {
        display: flex;
        justify-content: flex-start;
        padding: 7px 0 7px 3vw;
        flex-wrap: wrap;
      }
    `}
`;

export const Circle = styled.div`
  
  
`
export const Header = styled.div`
  display: grid;
  grid-gap: 12px;

  align-items: flex-start;
  align-content: start;
`;

export const Excerpt = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: ${({ hero }) => (hero ? 5 : 2)};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Meta = styled.div`
  display: grid;
  grid-gap: 12px;

  align-items: flex-start;
  align-content: start;
`;

export const Details = styled.div`
  display: grid;
  margin: 14px 0 0;

  ${({ hero }) =>
    hero &&
    css`
      @media (min-width: var(--breakpoint-lg)) {
        align-content: space-between;
      }
    `}
`;
